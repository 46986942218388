import { useRouter } from "next/router";
import { useCallback, useState } from "react";
import { useUserAuthenticate } from "../services/query/query";
import { RefreshTokenType } from "../types/types";
import { AxiosError } from "axios";
import useAuthProvider from "../store/AuthProvider";

export const useApiCall = () => {
  const [forceSign, setForceSign] = useState(false);
  const router = useRouter();

  const auth = useAuthProvider();

  const { refetch } = useUserAuthenticate({
    enabled: false,
    onSuccess: (data) => {
      if (data) {
        const userData = {
          id: data.result._id,
          email: data.result.email,
          name: data.result.name,
          profile: data.result.picture,
        };
        const token = data.result.token;

        auth.login(token, userData);
      }
    },
    onError: (e) => {
      const error = e as AxiosError;
      const statusCode = error.response?.status;
      if (statusCode !== 401 && statusCode !== 403) return;
      if (!forceSign) return;
      router.push("/signin");
    },
    retry: false,
  });

  const refreshToken = useCallback(async ({ forceSign }: RefreshTokenType) => {
    setForceSign(forceSign);
    await refetch();
  }, []);

  return { refreshToken };
};
