import axios, { AxiosError } from "axios";

const apiEndPoints = {
  postContactDetails: "/api/contact",
  userLogin: "/api/login",
  allUser: "/api/users",
  verifyUser: "/api/user/token",
  getUserInfo: "https://www.googleapis.com/oauth2/v3/userinfo",

  postConversation: "/api/chatbot/conversation",
};

export const postContactDetails = <TData, TVariables>(): ((
  variable: TVariables
) => Promise<TData>) => {
  return async (variable) => {
    try {
      const formData = JSON.stringify(variable);
      const data = await axios.post(apiEndPoints.postContactDetails, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return data.data;
    } catch (e) {
      const error = e as AxiosError;
      throw error.message;
    }
  };
};

export const userLogin = <TData, TVariables>(): ((
  variable: TVariables
) => Promise<TData>) => {
  return async (variable) => {
    try {
      const formData = JSON.stringify(variable);
      const data = await axios.post(apiEndPoints.userLogin, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return data.data;
    } catch (e) {
      const error = e as AxiosError;
      throw error.message;
    }
  };
};

// this will get the user info from the google api
export const getUserInfo = <TData, TVariables>(): ((
  variable: TVariables
) => Promise<TData>) => {
  return async (accessToken) => {
    try {
      const data = await axios.get(apiEndPoints.getUserInfo, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return data.data;
    } catch (e) {
      const error = e as AxiosError;
      throw error.message;
    }
  };
};

// --------------------------------- Query calls -----------------------------------

export const getAllUserInfo = <TData>(): (() => Promise<TData>) => {
  return async () => {
    try {
      const data = await axios.get(apiEndPoints.allUser);
      return data.data;
    } catch (e) {
      const error = e as AxiosError;
      throw error.message;
    }
  };
};

export const authUser = <TData>(): (() => Promise<TData>) => {
  return async () => {
    try {
      const { data } = await axios.get(apiEndPoints.verifyUser, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return data;
    } catch (e) {
      const error = e as AxiosError;
      throw error;
    }
  };
};

// ----------------------------- Chatbot calls -------------------------------

export const postConversation = <TData, TVariables>(): ((
  variable: TVariables
) => Promise<TData>) => {
  return async (variable) => {
    try {
      const formData = JSON.stringify(variable);
      const data = await axios.post(apiEndPoints.postConversation, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return data.data;
    } catch (e) {
      const error = e as AxiosError;
      throw error.message;
    }
  };
};
