import { AxiosError } from "axios";
import { UserInfoType, UserResponseType } from "../../types/types";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { authUser, getAllUserInfo } from "../services";

export const useGetAllUserInfo = <TData = UserInfoType, TError = AxiosError>(
  options?: UseQueryOptions<UserInfoType, TError, TData>
) => {
  return useQuery<UserInfoType, TError, TData>(
    ["getUserInfo"],
    getAllUserInfo<UserInfoType>(),
    options
  );
};

export const useUserAuthenticate = (
  options?: UseQueryOptions<UserResponseType, AxiosError>
) => {
  return useQuery<UserResponseType, AxiosError>(
    ["authUser"],
    authUser<UserResponseType>(),
    options
  );
};
