import React, { useEffect } from "react";
import { Container, Typography, useTheme } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import welcomeAnimation from "../assets/lottieFiles/9757-welcome.json";
import { useApiCall } from "../util/ApiCall";

export default function Home() {
  const theme = useTheme();
  const { refreshToken } = useApiCall();

  useEffect(() => {
    refreshToken({ forceSign: false });
  }, []);

  return (
    <Container
      sx={{
        minHeight: "50vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 3,
        py: 3,
      }}
    >
      <Typography
        variant="h2"
        sx={{ textAlign: "center", maxWidth: "750px" }}
        color={theme.palette.primary.main}
      >
        Welcome to my portfolio website. Here you&apos;ll find a collection of
        my work, from web design to branding and beyond. Take a look around and
        feel free to get in touch.
      </Typography>
      <Player
        autoplay
        loop
        src={welcomeAnimation}
        style={{ width: "auto", height: "70vh" }}
      />
    </Container>
  );
}

export async function getServerSideProps() {
  const metaTags = {
    title: "Portfolio | Krushna Raut",
    description:
      "Krushna Raut is a talented web developer with a passion for creating beautiful, functional, and user-friendly websites. Browse his personal portfolio to discover his best projects, skills, and expertise. Get in touch with Krushna for your next web development project and see how he can help you achieve your goals.",
  };
  return {
    props: {
      metaTags,
    },
  };
}
